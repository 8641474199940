<template>
  <div
    class="select sort-dropdown"
    :class="{'open': isOpenSelect}"
  >
    <div
      class="select-element"
      @click="selectHandler"
    >
      <slot></slot>
      <span v-html="initSelect"></span>
      <img
        alt="row"
        class="select-arrow"
        src="~/assets/img/svg/header/arrow-down-gray.svg"
      >
    </div>

    <template v-if="isOpenSelect">
      <div class="scroll-customize select-dropdown">
        <template
          v-for="(option,id) in selectList"
          :key="`select-dropdown-itm-${id}`"
        >
          <div
            class="select-dropdown-row"
            @click="() => changeSort(option)"
          >
            <span>{{ option[labelName] }}</span>
          </div>
        </template>
      </div>
      <div
        class="select-overlay"
        @click="selectHandler"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
	selectList: Array<any>;
	activeValue?: string;
	labelName: string;
	selectedSort: any;
}>();

const emits = defineEmits<{
	emitOptions: [options: any]
}>();

const { $priceFormat } = useNuxtApp();
const isOpenSelect = ref<boolean>(false);

const selectHandler = () => {
  isOpenSelect.value = !isOpenSelect.value;
};

const changeSort = (options: any) => {
  emits('emitOptions', options);
  selectHandler();
};

const initSelect = computed(() => props?.selectedSort?.[props.labelName] || props.activeValue || props.selectList?.[0]?.[props.labelName]);
</script>

<style lang="scss">
@import "@/assets/scss/media";

.select-element {
	padding: 8px 30px 8px 15px;
	border: none;
	background: var(--gray-light);
	display: flex;
	align-items: center;

	& > span {
		display: flex;
		justify-content: space-between;
		width: 100%;
		color: var(--primary);
		font-weight: 500;
		margin-left: 30px;
	}

	& img {
		top: 20px;
		filter: invert(85%) sepia(15%) saturate(4580%) hue-rotate(345deg) brightness(102%) contrast(101%);
	}
}

.select-dropdown {
	top: 100%;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border: none;
}

.select-dropdown > div:not(:last-child) {
	border-bottom: none;
	position: relative;

	&::after {
		content: "";
		display: block;
		position: absolute;
		width: calc(100% - 40px);
		bottom: 0;
		left: 10px;
		height: 1px;
		background: var(--stroke);
	}
}

.select-dropdown-row {
	background-color: var(--gray-light);
	display: flex;
	justify-content: space-between;
	color: var(--black-light);
	font-size: 14px;
	font-weight: 400 !important;
	padding: 20px;
}

.select-img {
	position: static !important;
	width: 16px;
	transform: translateY(0) !important;
}
</style>
